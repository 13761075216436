import styled from "styled-components";
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader";
import * as MdIcons from 'react-icons/md';
import { ModalFooter } from "../../../../Constants/StyledComponents";
import { useEffect, useState, useRef, useCallback } from "react";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import { ControlledTabs } from "../../../../Components/Tabs/Tabs";
import { Column } from "../../../../Components/Layout/Column";
import { TabSelector } from "../../../../Components/Tabs/TabSelector";
import EditAnnotationModalities from "./EditAnnotationModalities";
import { useAnnotationsProvider } from "../../../../Providers/AnnotationsProvider";
import CancelEditAnnotationModal from "./CancelEditAnnotationModal";
import DeleteEditAnnotationModal from "./DeleteEditAnnotationModal";
import CircularCheckboxComponent from "../../../../Components/CircularCheckbox/CircularCheckbox";
import TrashButton from "../../../../Components/TrashButton/TrashButton";
import { selectedAnnotationAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Annotations";
import { useRecoilValue } from "recoil";
import ModalFooterButton from "../../../../Components/ModalFooterButton/ModalFooterButton";
import TimeSelector from "./TimeSelector";
import { currentPatientFileInfoAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/PatientFile"
import { useAnnotationService } from "../../../../Hooks/useAnnotationService";
import MultivalueTextField from "../../../../Components/TextField/MultivalueTextField";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { updateHotkeyTrigger } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Hotkeys";
import { ChromePicker } from 'react-color';

export function EditAnnotation(props) {
    const annotationsProvider = useAnnotationsProvider()

    const { close, createModal } = useModalProvider()

    const selectedAnnotationAtomAnnotation = useRecoilValue(selectedAnnotationAtom)

    // if the EditAnnotation modal is opened up from HotkeysModal.js, then the configs should come from props rather than the selectedAnnotationAtom
    const selectedAnnotation = props.mode === "CREATE" ? props.config : props.mode === "EDIT" ? { ...props.config.data, id: props.config.id } : selectedAnnotationAtomAnnotation

    // start datetime and end datetime for the entire patient file. these are not annotation start/end times
    const { fileStartDate, fileEndDate } = useRecoilValue(currentPatientFileInfoAtom)

    // the fileTimeZone variable stores the time zone of the entire patient file, NOT the annotation creation datetime timezone
    const fileTimeZone = (new Date(fileStartDate)).toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]

    const { modifyAnnotation, deleteAnnotations } = useAnnotationService()

    // handles discarding unsaved changes when pressing 'Cancel' button. never call __setUneditedAnnotationConfigs directly because of shallow copy. instead, use setUneditedAnnotationConfigs callback
    const [uneditedAnnotationConfigs, __setUneditedAnnotationConfigs] = useState(structuredClone(selectedAnnotation))

    // never call __setUneditedAnnotationConfigs directly because of shallow copy. use this callback to handle setting a new value for uneditedAnnotationConfigs
    const setUneditedAnnotationConfigs = useCallback((s) => {
        __setUneditedAnnotationConfigs(structuredClone(s))
    }, [__setUneditedAnnotationConfigs])

    //useState handles storing annotator name as a string from get_annotations() endpoint
    const [annotatorName, setAnnotatorName] = useState(selectedAnnotation.user_full_name)

    //useState handles storing annotator email as a string from get_annotations() endpoint
    const [annotatorEmail, setAnnotatorEmail] = useState(selectedAnnotation.user_email)

    //useState handles storing annotation creation timestamp as a UTC timestamp from get_annotations() endpoint
    const [annotationTimestamp, setAnnotationTimestamp] = useState(convertTimestamp(selectedAnnotation.timestamp))

    // this variable handles storing the local time zone of the user/reviewer
    const reviewerTimeZone = (new Date()).toLocaleString('en-US', { timeZoneName: 'short' }).split(' ')[3]

    // useState handles storing annotation description as a string
    const [annotationDescription, setAnnotationDescription] = useState(selectedAnnotation.description)

    // useState handles storing annotation title as a string
    const [annotationTitle, setAnnotationTitle] = useState(selectedAnnotation.text)

    // useState handles holding annotation tags as an array of strings. ex.) ['tag1', 'tag2']
    const [annotationTags, setAnnotationTags] = useState(selectedAnnotation.tags)

    // useState stores error message as a string when validating tags
    const [tagError, setTagError] = useState('')

    const tagInputRef = useRef(null);
    const tagsContainerRef = useRef(null)
    const annotationTitleInputRef = useRef(null)

    // useState renders input box when user adds a new tag (Boolean; true = render input box to add a new tag)
    const [isAddTag, setIsAddTag] = useState(false)

    // useState stores input/value as a string when adding a new tag
    const [newTagValue, setNewTagValue] = useState('')

    // boolean useState becomes true when there are unsaved edits/changes
    const [unsavedEdits, setUnsavedEdits] = useState(false)

    // Annotation Interval tab is not applicable when editing keyboard shortcuts/hotkeys
    const tabs = props.isHotkeysModal ? ['Modalities'] : ['Modalities', 'Annotation Interval']

    // by default, start with Modalities tab selected in EditAnnotationModal
    const [selectedTab, setSelectedTab] = useState('Modalities')

    // handles storing the original value of annotationsProvider.isPointAnnotation as a Boolean to handle enabling/disabling the Save button when switching between span and point annotations
    const [intervalStatus, setIntervalStatus] = useState(annotationsProvider.isPointAnnotation)

    const [unsavedHotkeyConfig,] = useState(structuredClone(props.config))
    const [hotkeyConfig, setHotkeyConfig] = useState(structuredClone(props.config))

    /*
     * A React useEffect hook that handles setting values of annotationsProvider.isPointAnnotation based on original, unedited annotation start and end times
     * This useEffect only runs on the first render
    */
    useEffect(() => {
        // only check start and end times when editing an annotation, not a keyboard shortcut/hotkey
        if (!props.isHotkeysModal) {
            // In point annotations, start and end times are exactly the same. If not, the annotation is a span annotation
            if (uneditedAnnotationConfigs.start_time === uneditedAnnotationConfigs.end_time) {
                annotationsProvider.setIsPointAnnotation(true)
                setIntervalStatus(true)

            } else {
                annotationsProvider.setIsPointAnnotation(false)
                setIntervalStatus(false)
            }
        } else {
            // Keyboard shortcuts/hotkeys do not have an annotation timestamp, annotator name, or annotator email
            setAnnotationTimestamp()
            setAnnotatorName("")
            setAnnotatorEmail("")
            if (props.mode === "CREATE" && !props.isHotkeysModal) {
                annotationTitleInputRef.current.focus()
            }
        }
    }, [])

    /**
    * Handles formatting UTC timestamps in the form: '9/8/23 @ 12:09PM'
    *
    * @param {UTC Integer} timestamp - A timestamp in UTC form.
    */
    function convertTimestamp(timestamp) {
        const dateObj = new Date(timestamp);
        const formattedDate = dateObj.toLocaleDateString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
        });
        const formattedTime = dateObj.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });

        return `${formattedDate} @ ${formattedTime}`;
    }

    // handles color value for annotation configs (hexcode form)
    const [annotationColor, setAnnotationColor] = useState(selectedAnnotation.color)

    // useState stores opacity value for annotation configs as a float (ex. 0.2 opacity = 20% opacity)
    const [annotationOpacity, setAnnotationOpacity] = useState(0.2)

    /**
    * Handles the logic for updating the color state after receiving the updated color object when the color selection changes
    *
    * @param {Color Object} nextState - The updated color object when color selection changes in the ColorPicker
    */
    const handleColorChange = (color) => {
        const hex = color.hex
        setAnnotationColor(hex)
        setUnsavedEdits(true)
    }

    // useState contains the start datetime of the selected annotation as an integer
    const [startDate, setStartDate] = useState(selectedAnnotation.start_time)

    // useState contains the end datetime of the selected annotation as an integer
    const [endDate, setEndDate] = useState(selectedAnnotation.end_time ? selectedAnnotation.end_time : selectedAnnotation.start_time) // if end_time doesn't exist, this means the annotation is a Point Annotation. In this case, end_time = start_time

    /**
    * Handles the logic for updating the startDate and endDate useStates after making changes in the TimeSelector
    *
    * @param {Integer} start - The updated end datetime when end time is changed in TimeSelector
    * @param {Integer} end - The updated end datetime when end time is changed in TimeSelector
    */
    function handleTimeChange(start, end) {
        setStartDate(start)
        setEndDate(end)
    }

    /*
     * A React useEffect hook that detects click events and handles focusing/blurring the new tag input box. This useEffect also handles behavior when clicking outside the tags container to exit the workflow
    */
    useEffect(() => {
        // Function to handle clicks outside of the target div
        const handleOutsideClick = (event) => {
            if ((tagsContainerRef.current && !tagsContainerRef.current.contains(event.target)) || (event.target.id !== "tagsContainerDiv" && event.target.id !== "addMoreTagsButton")) {
                if (newTagValue.length > 0) {
                    if ((!annotationTags.includes(newTagValue.trim())) && newTagValue.trim() !== '') {
                        setAnnotationTags(prevTags => [
                            ...prevTags,
                            newTagValue
                        ])
                    }
                    setNewTagValue('')
                    tagInputRef.current.blur()
                }
                setIsAddTag(false)
            } else if (event.target.id === "tagsContainerDiv" || event.target.id === "addMoreTagsButton") {
                // Click occurred inside the target div
                if (isAddTag) {
                    tagInputRef.current.focus()
                }
            }
        }

        // Attach the click event listener to the document
        document.addEventListener('click', handleOutsideClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    })


    /**
    * Handles the logic for removing/deleting a tag by pressing it's corresponding 'X' button
    *
    * @param {String} tagName - The name of the selected tag
    */
    function removeTag(tagName) {
        setAnnotationTags(prevState => prevState.filter(tag => tag !== tagName))
        setTagError("")
    }

    function addTag(value, setChips) {
        // tagExists is a Boolean. It is true if the annotationTags array already includes the new tag value
        const tagExists = annotationTags.includes(value)
        // Pressing 'Enter' begins the validation process for adding a tag
        if (value.trim() === '') { // Checks if the new tag is blank or whitespace
            setTagError("")
            return
        } else if (value.length > 25) {
            setTagError('Tags cannot be longer than 25 characters.')
        } else if (tagExists) { // Checks if the new tag already exists
            setTagError('Tag already exists.')
        } else { // If new tag is valid, continue adding the tag to the annotationTags array
            setAnnotationTags(prevTags => [
                ...prevTags,
                value
            ])
            setChips(prev => [...prev, value])
            setTagError('')
        }
    }


    /**
    * Handles functionality for any key press actions when modifying the annotation title
    *
    * @param {onKeyDown Event} event - The key pressed while typing in a new annotation title value
    */
    const saveNewAnnotationTitle = (event) => {
        if (event.key === 'Enter') { // Pressing 'Enter' saves the new annotation title
            event.target.blur()
        }
    }

    /*
     * A React useEffect hook that handles disabling/enabling for Save button (unsavedEdits useState).
     * If the EditAnnotation modal is opened up from HotkeysModal.js, it is unnecessary to check for changes in start_time, end_time, and interval status
    */
    useEffect(() => {
        const annotationModalities = JSON.stringify(annotationsProvider.annotationModalities.slice().sort())
        const uneditedAnnotationModalities = JSON.stringify(annotationsProvider.uneditedModalitiesProvider.slice().sort())
        const currentAnnotationTags = annotationTags.slice().sort().join('')
        const uneditedAnnotationTags = uneditedAnnotationConfigs.tags.slice().sort().join('')

        if (annotationModalities !== uneditedAnnotationModalities) { // checks for changes in selected annotation's modalities
            setUnsavedEdits(true)
            annotationsProvider.setUnsavedModalityEdits(true)
        } else if (annotationTitle !== uneditedAnnotationConfigs.text) { // checks for changes in selected annotation's title
            setUnsavedEdits(true)
            annotationsProvider.setUnsavedModalityEdits(false)
        } else if (annotationDescription !== uneditedAnnotationConfigs.description) { // checks for changes in selected annotation's description
            if (annotationDescription.length > 500) {
                setUnsavedEdits(false)

            } else {
                setUnsavedEdits(true)
            }
            annotationsProvider.setUnsavedModalityEdits(false)
        } else if (annotationColor !== uneditedAnnotationConfigs.color) {
            setUnsavedEdits(true)
            annotationsProvider.setUnsavedModalityEdits(false)
        } else if (currentAnnotationTags !== uneditedAnnotationTags) { // checks for changes in selected annotation's tags
            if (String(tagError).length > 0 || annotationTags.length > 10) {
                setUnsavedEdits(false)
                annotationsProvider.setUnsavedModalityEdits(false)
            } else {
                setUnsavedEdits(true)
                annotationsProvider.setUnsavedModalityEdits(false)
            }
        } else if (startDate && (parseInt(startDate) !== parseInt(uneditedAnnotationConfigs.start_time)) && !props.isHotkeysModal) { // checks if the selected annotation is a keyboard shortcut/hotkey and if not, checks for changes in selected annotation's start datetime
            setUnsavedEdits(true)
            annotationsProvider.setUnsavedModalityEdits(false)
        } else if (endDate && (parseInt(endDate) !== parseInt(uneditedAnnotationConfigs.end_time)) && !props.isHotkeysModal) { // checks if the selected annotation is a keyboard shortcut/hotkey and if not, checks for changes in selected annotation's end datetime
            setUnsavedEdits(true)
            annotationsProvider.setUnsavedModalityEdits(false)
        } else if (annotationsProvider.isPointAnnotation !== intervalStatus && (!props.isHotkeysModal)) { // checks if the selected annotation is a keyboard shortcut/hotkey and if not, checks for changes in selected annotation's interval status (whether it's a point or span annotation)
            setUnsavedEdits(true)
            annotationsProvider.setUnsavedModalityEdits(false)
        } else if (props.isHotkeysModal && JSON.stringify(unsavedHotkeyConfig?.triggers) !== JSON.stringify(hotkeyConfig?.triggers)) {
            setUnsavedEdits(true)
        } else { // no changes have been made, so disable the 'Save' button
            setUnsavedEdits(false)
            annotationsProvider.setUnsavedModalityEdits(false)
        }
    }, [annotationTitle, annotationDescription, annotationTags, annotationsProvider.annotationModalities, startDate, endDate, annotationsProvider.isPointAnnotation, props.isHotkeysModal, annotationColor, hotkeyConfig, unsavedHotkeyConfig])

    /**
    * Handles opening up the delete annotation confirmation modal
    */
    const renderDeleteAnnotationModal = () => {
        createModal(<DeleteEditAnnotationModal
            escClose={false}
            clickOutClose={false}
            deleteAction={() => { handleCloseAndDeleteAnnotation() }}
            isHotkeysModal={props.isHotkeysModal}
        />)
    }

    async function handleCloseAndDeleteAnnotation() {
        await handleDeleteAnnotation()
        // First close will close the confirmation modal. The second closes the main modal.
        await close()
        await close()
    }

    /**
    * Handles deleting the selectedAnnotation and closing both the Delete Annotation Confirmation and Edit Annotation modals
    */
    async function handleDeleteAnnotation() {
        if (props.isHotkeysModal) { // handle deleting a keyboard shortcut/hotkey
            props.onDelete(selectedAnnotation)
            annotationsProvider.resetAnnotationsProvider()
        } else { // handle deleting an annotation
            await deleteAnnotations([selectedAnnotation.id])
            annotationsProvider.resetAnnotationsProvider()
        }
    }

    async function handleCloseAndSaveAnnotation() {
        await handleSaveAnnotation()
        await close()
    }

    /**
    * Handles opening up the cancel changes confirmation modal if there are unsaved changes.
    * If there are no unsaved changes, just close the modal.
    */
    async function renderCancelConfirmationModal() {
        if (unsavedEdits || annotationsProvider.unsavedModalityEdits || (annotationTags.slice().sort().join('') !== uneditedAnnotationConfigs.tags.slice().sort().join(''))) {
            createModal(<CancelEditAnnotationModal
                escClose={false}
                clickOutClose={false}
                disableSave={((annotationTags.slice().sort().join('') !== uneditedAnnotationConfigs.tags.slice().sort().join('')) && annotationTags.length > 10) || annotationDescription.length > 500}
                cancelAction={() => { cancelUnsavedChanges() }}
                saveAction={() => { handleCloseAndSaveAnnotation() }}

            />)
        } else {
            setUnsavedEdits(false)
            annotationsProvider.setUnsavedModalityEdits(false)
            close()
        }
    }

    /**
    * Handles reverting all annotation configs to the last saved state, resetting the AnnotationsProvider, and closing the Cancel Confirmation and Edit Annotation modals
    */
    async function cancelUnsavedChanges() {
        setAnnotationTitle(uneditedAnnotationConfigs.text)
        setAnnotationDescription(uneditedAnnotationConfigs.description)
        setAnnotationTags(uneditedAnnotationConfigs.tags)
        setAnnotationColor(uneditedAnnotationConfigs.color)
        setAnnotationOpacity(0.2)
        setStartDate(uneditedAnnotationConfigs.start_time)
        setEndDate(uneditedAnnotationConfigs.end_time)
        setUnsavedEdits(false)
        // First close will close the confirmation modal. The second closes the main modal.
        await close()
        await close()
        annotationsProvider.resetAnnotationsProvider()
    }

    /**
    * Handles creating a new keyboard shortcut/hotkey.
    */
    const createNewAnnotationShortcut = (config) => {
        if (props.onSave) {
            props.onSave(config, props.mode)
        }
    }

    const [errorMessage, setErrorMessage] = useState(undefined)

    /**
    * Handles saving changes to an annotation configuration, saving changes to a keyboard shortcut's configurations, or saving changes after creating a new keyboard shortcut
    */
    async function handleSaveAnnotation() {
        if (!props.isHotkeysModal && !annotationsProvider.isPointAnnotation) {
            if (startDate > endDate) {
                setErrorMessage("Start time cannot be greater than the end time")
                return
            } else if (startDate === endDate) {
                setErrorMessage("Start time cannot be equal to the end time. Change to point annotation instead")
                return
            }
        }

        const data = {
            id: selectedAnnotation.id,
            start_time: startDate,
            end_time: annotationsProvider.isPointAnnotation ? startDate : endDate,
            text: annotationTitle,
            description: annotationDescription,
            type: selectedAnnotation.type,
            color: annotationColor,
            opacity: annotationOpacity,
            modalities: Object.keys(annotationsProvider.annotationModalities).filter(index => annotationsProvider.annotationModalities[index]['checked'] === true).map(modality => annotationsProvider.annotationModalities[modality]['label']),
            tags: annotationTags,
            loaded: true,
            group_id: selectedAnnotation.group_id,
            user_id: selectedAnnotation.user_id
        }
        if (props.onSave && !props.isHotkeysModal) {
            props.onSave(data)
            handleModifyExistingAnnotation(data)
            await close()
            annotationsProvider.resetAnnotationsProvider()
        } else if (props.isHotkeysModal) { // handles Save actions for keyboard shortcuts
            if (props.mode === "CREATE" || selectedAnnotation.id.toString().startsWith("ann_")) {
                if (props.onSave) {
                    data.triggers = hotkeyConfig?.triggers
                    props.onSave(data, props.mode)
                    setUneditedAnnotationConfigs(data)
                    annotationsProvider.setUneditedModalitiesProvider(annotationsProvider.annotationModalities)
                    setUnsavedEdits(false)
                    annotationsProvider.setUnsavedModalityEdits(false)
                    await close()
                    annotationsProvider.resetAnnotationsProvider()
                    return
                }
            }
            if (selectedAnnotation.id.toString().startsWith("TEMP")) {
                createNewAnnotationShortcut(data)
                await close()
                annotationsProvider.resetAnnotationsProvider()
                return
            }
        } else { // handles Save actions for annotations
            handleModifyExistingAnnotation(data)
            await close()
            annotationsProvider.resetAnnotationsProvider()
        }
    }

    /**
    * Handles saving changes to an annotation configuration, saving changes to a keyboard shortcut's configurations, or saving changes after creating a new keyboard shortcut
    * 
    * @param {Dictionary} data - The new configurations including all changes made to the selected annotation
    */
    const handleModifyExistingAnnotation = (data) => {
        modifyAnnotation(selectedAnnotation, data)
            .catch(err => console.warn(err))
            .finally(() => {
                setUneditedAnnotationConfigs(data)
                annotationsProvider.setUneditedModalitiesProvider(annotationsProvider.annotationModalities)
                setUnsavedEdits(false)
                annotationsProvider.setUnsavedModalityEdits(false)
            })
    }

    const schema = yup.object({
        tags: yup.array()
            .of(
                yup.string()
                    .trim()
                    .max(25, 'Each tag has a 25 character limit.')
                    .test(
                        'is-unique',
                        'Tag already exists',
                        (value) => !annotationTags.includes(value)
                    )
            )
    })

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { tags: [] },
        mode: "onChange",
    })

    const [assignShortcutWarning, setAssignShortcutWarning] = useState('')

    const handleChangeKeyboardShortcut = useCallback((event, config) => {
        const excludedKeyCodes = [
            // Function keys (F1 - F12)
            112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,

            // Navigation keys
            33, 34, 35, 36,

            // Modifiers
            16, 17, 18, 20, 91, 92, 93,

            // Lock keys
            144, 145,

            // Other control keys
            45, 46, 19, 9, 8, 13, 27,

            // Space bar
            32
        ]
        if (!config) {
            return
        }
        let newTrigger = event.key
        let newTriggerCode = event.keyCode
        const usedKeys = props.hotkeyConfigs?.map(config => config.triggers).flat(1).filter(key => key !== null)

        if (config.triggers[0] === newTrigger) {
            setHotkeyConfig(prev => { return { ...prev, isInEditMode: false, editModeWarning: false } })

            return
        } else if (usedKeys.includes(newTrigger) || newTrigger === "ArrowRight" || newTrigger === "ArrowLeft") {
            setAssignShortcutWarning(`'${newTrigger}' shortcut already in use`)
            setHotkeyConfig(prev => { return { ...prev, editModeWarning: true } })

            return
        } else if (excludedKeyCodes.includes(newTriggerCode)) {
            if (newTriggerCode === 32) {
                setAssignShortcutWarning("Space key can't be used")
            } else {
                setAssignShortcutWarning(`${newTrigger} key can't be used`)
            }
            setHotkeyConfig(prev => { return { ...prev, editModeWarning: true } })
            return false
        } else {
            setUnsavedEdits(true)
            setHotkeyConfig(prev => {
                updateHotkeyTrigger(prev, "Primary", newTrigger)
                return { ...prev, isInEditMode: false, editModeWarning: false }
            })
            return
        }
    }, [props.hotkeyConfigs])

    useEffect(() => {
        window.addEventListener('keydown', (event) => {
            if (hotkeyConfig?.isInEditMode) {
                handleChangeKeyboardShortcut(event, hotkeyConfig)
            }
        }, { once: true })
    }, [hotkeyConfig, handleChangeKeyboardShortcut])

    function handleChangeKeyboardShortcutEditMode() {
        setHotkeyConfig(prev => { return { ...prev, isInEditMode: true } })
    }

    function getPrettyHotkeyTrigger(config) {
        if (!config?.triggers || config.triggers[0] === null) {
            return null
        }

        const trigger = config.triggers[0].toLowerCase()

        switch (trigger) {
            case "arrowright": return <MdIcons.MdArrowRightAlt />
            case "arrowleft": return <MdIcons.MdArrowRightAlt style={{ transform: "rotate(180deg)" }} />
            case "arrowdown": return <MdIcons.MdArrowRightAlt style={{ transform: "rotate(90deg)" }} />
            case "arrowup": return <MdIcons.MdArrowRightAlt style={{ transform: "rotate(270deg)" }} />
            case "escape": return "Esc"
            case " ": return "spacebar"
            default: return trigger
        }
    }

    const colorPickerRef = useRef(null)
    const colorPickerBlockRef = useRef(null)

    function toggleColorPicker(event) {
        const node = colorPickerRef.current

        if (node?.style.display === "block") {
            node.style.display = "none"
            return
        }

        if (node) {
            node.style.display = "block"
            const iconRect = event.target.getBoundingClientRect()
            node.style.top = `${iconRect.bottom}px`
            node.style.left = `${iconRect.right - node.offsetWidth}px`
        }
    }

    useEffect(() => {
        const clickHandler = (event) => {
            let clickedOutside = true

            if (colorPickerBlockRef.current?.contains(event.target)) {
                clickedOutside = false
            }

            if (colorPickerRef.current?.contains(event.target)) {
                clickedOutside = false
            }

            if (clickedOutside && colorPickerRef.current) {
                colorPickerRef.current.style.display = "none"
            }
        }

        document.addEventListener("click", clickHandler)
        return () => document.removeEventListener("click", clickHandler)
    })

    return (
        <EditAnnotationContainer>
            <ModalHeader headerText={props.isHotkeysModal ? props.mode === "CREATE" ? "Create Shortcut" : "Edit Shortcut" : "Edit Annotation"}
                closeFxn={() => {
                    if ((!unsavedEdits) && (annotationTags.slice().sort().join('') === uneditedAnnotationConfigs.tags.slice().sort().join(''))) {
                        if (props.isHotkeysModal) {
                            close()
                            annotationsProvider.resetAnnotationsProvider()
                        } else {
                            close()
                            annotationsProvider.resetAnnotationsProvider()
                        }
                    } else { renderCancelConfirmationModal() }
                }}
            />

            <EditAnnotationContentDiv>
                <div style={{ display: "flex", width: "706px", alignItems: "center" }}>
                    <AnnotationTitleInput type="text" value={annotationTitle} onChange={(e) => { setAnnotationTitle(e.target.value) }} onKeyDown={saveNewAnnotationTitle} maxLength={55} ref={annotationTitleInputRef} disabled={(props.isHotkeysModal && JSON.stringify(hotkeyConfig?.triggers) === '[null,null]') || hotkeyConfig?.editModeWarning || hotkeyConfig?.isInEditMode} />

                    <div ref={colorPickerBlockRef} style={{ display: "block", cursor: "pointer", marginLeft: "12px", marginRight: '12px' }} onClick={(e) => { toggleColorPicker(e) }}>
                        <MdIcons.MdOutlineFormatColorFill size={28} style={{ color: "#AEB7C6", marginTop: "4px" }} />

                        <div id='ColorLine' style={{ height: "6px", width: "28px", backgroundColor: annotationColor, marginTop: "-6px", position: "relative" }} />
                    </div>

                    <div id={`ColorPickerWindow`} ref={colorPickerRef} style={{ display: 'none', position: 'fixed', zIndex: 1 }}>
                        <ChromePicker
                            color={annotationColor}
                            onChange={handleColorChange}
                        />
                    </div>

                    <TrashButton
                        buttonStyle={{ height: "40px", width: "40px", display: props.mode === "CREATE" ? "none" : "block" }}
                        onClickFxn={renderDeleteAnnotationModal}
                    />
                </div>

                <div style={{ display: props.isHotkeysModal ? 'flex' : 'none', padding: '10px 0px 0px', alignItems: 'center' }}>
                    <MdIcons.MdOutlineKeyboard
                        size={24}
                        style={{ color: "black", marginRight: "4px" }}
                        onClick={() => { }} />
                    <h1 style={{ margin: "0px", fontFamily: 'Source Sans Pro', fontWeight: 600, fontSize: '16px', color: '#293241', marginRight: '10px' }}>Assigned Key</h1>

                    <KeyboardButton style={{ color: "#207DEA", display: hotkeyConfig?.isInEditMode ? "none" : "block", background: "#E5F5FD", cursor: "pointer", marginRight: '3px' }} onClick={() => { handleChangeKeyboardShortcutEditMode(hotkeyConfig) }}>
                        {getPrettyHotkeyTrigger(hotkeyConfig)}
                    </KeyboardButton>

                    <AssignShortcutMessage
                        placeholder="Press key to assign shortcut"
                        style={{ display: hotkeyConfig?.editModeWarning ? "none" : hotkeyConfig?.isInEditMode ? "block" : "none" }}
                    >
                        Press key to assign shortcut
                    </AssignShortcutMessage>
                    <AssignShortcutWarning
                        style={{ display: hotkeyConfig?.editModeWarning ? "block" : "none" }}
                        onClick={() => { handleChangeKeyboardShortcutEditMode(hotkeyConfig) }}>
                        {assignShortcutWarning}
                    </AssignShortcutWarning>
                </div>

                <div style={{ width: "420px", marginTop: "6px", display: props.isHotkeysModal ? "none" : "flex", height: "50px" }}>
                    <div style={{ display: "flex", marginRight: '20px' }}>
                        <MdIcons.MdAccountCircle size={18} style={{ color: "#293241", marginTop: "3px", marginRight: "4px" }} />
                        <h1 style={{ fontSize: "16px", fontWeight: "600" }}>Annotator</h1>
                    </div>

                    <div>
                        <div style={{ display: "flex" }}>
                            <p>{annotatorName}</p>
                            <p style={{ color: "#AEB7C6", textDecorationLine: "underline", marginLeft: "8px" }}>{annotatorEmail}</p>
                        </div>
                        <p style={{ marginTop: "-18px" }}>{annotationTimestamp + " " + reviewerTimeZone}</p>
                    </div>
                </div>

                <div style={{ width: "705px", height: "145px", display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                    <AnnotationDescriptionTextInput
                        style={{ marginTop: props.isHotkeysModal ? "16px" : "0px", border: annotationDescription.length > 500 ? "1px solid #E54E58" : "1px solid #AEB7C6" }}
                        value={annotationDescription}
                        onChange={(e) => { setAnnotationDescription(e.target.value) }}
                        placeholder={props.isHotkeysModal ? "Write shortcut description here" : "Write annotation description here"}
                        onFocus={(e) => {
                            if (annotationDescription.length > 500) {
                                e.target.style.border = '1px solid #E54E58'
                            } else {
                                e.target.style.border = '1px solid #207DEA'
                            }
                        }}
                        onBlur={(e) => {
                            // Remove styling when the input loses focus
                            if (annotationDescription.length > 500) {
                                e.target.style.border = '1px solid #E54E58'
                            } else {
                                e.target.style.border = '1px solid #AEB7C6'; // Restore default border color
                            }
                        }}
                        maxLength={600}
                        disabled={(props.isHotkeysModal && JSON.stringify(hotkeyConfig?.triggers) === '[null,null]') || hotkeyConfig?.editModeWarning || hotkeyConfig?.isInEditMode}
                    />

                    <div id="descriptionCharacterCount" style={{ fontSize: "14px", fontFamily: 'Source Sans Pro', color: annotationDescription.length > 500 ? "#E54E58" : "#293241", width: "fit-content", height: "fit-content", alignSelf: 'end' }}>
                        {annotationDescription.length} / 500 characters
                    </div>

                </div>

                <TagsContentDiv>
                    <div style={{ display: 'flex', flex: 1 }}>
                        <div style={{ display: 'flex', marginRight: '10px' }}>
                            <MdIcons.MdSell size={15} style={{ color: "#293241", marginRight: "4px", marginTop: '5px' }} />
                            <h1 style={{ margin: "0px" }}>Tags</h1>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <MultivalueTextField
                                inputId="annotation-tags-id"
                                label=""
                                tooltip=""
                                placeholder="+ add more tags"
                                autoFocus={false}
                                control={control}
                                name="tags"
                                numberColumn={5}
                                required={false}
                                setterFunction={addTag}
                                deleteFunction={removeTag}
                                preselect={annotationTags}
                                errors={tagError}
                                styles={{ background: (props.isHotkeysModal && JSON.stringify(hotkeyConfig?.triggers) === '[null,null]') || hotkeyConfig?.editModeWarning || hotkeyConfig?.isInEditMode ? '#FAFAFA' : '#FFFFFF', maxHeight: '100px', overflow: 'auto', marginTop: '0px' }}
                                disabled={(props.isHotkeysModal && JSON.stringify(hotkeyConfig?.triggers) === '[null,null]') || hotkeyConfig?.editModeWarning || hotkeyConfig?.isInEditMode}
                            />

                            <div id="tagsCount" style={{ fontSize: "14px", fontFamily: 'Source Sans Pro', color: annotationTags.length > 10 ? "#E54E58" : "#293241", width: "fit-content", height: "fit-content", marginLeft: 'auto' }}>
                                {`${annotationTags.length} / 10 tags`}
                            </div>
                        </div>
                    </div>
                </TagsContentDiv>

                <div style={{ width: props.isHotkeysModal ? "70px" : "240px", marginLeft: "auto", marginRight: "auto" }}>
                    <ControlledTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
                        <Column>
                            <TabSelector tabKeys={tabs} style={{ whiteSpace: "nowrap" }} />
                        </Column>
                    </ControlledTabs>
                </div>
                <hr style={{ border: "1px solid #EFF1F4", marginTop: "-3px" }} />

                <ModalitiesContentContainer style={{ display: selectedTab === 'Modalities' ? "block" : "none", overflowY: "scroll" }}>
                    <EditAnnotationModalities config={hotkeyConfig ? hotkeyConfig : null} mode={props.mode ? props.mode : null} isHotkeysModal={props.isHotkeysModal} />
                </ModalitiesContentContainer>

                <AnnotationIntervalContentContainer style={{ display: selectedTab === 'Annotation Interval' ? "block" : "none", maxHeight: "310px", marginoverflowY: "hidden", pointerEvents: "all" }}>
                    <div style={{ display: "inline-flex", marginLeft: "30px", marginTop: "35px", transform: "scale(0.9)", zIndex: "100", position: "relative", transformOrigin: "top" }}>
                        <CircularCheckboxComponent
                            style={{ color: annotationsProvider.isPointAnnotation ? "#207DEA" : "#B6B6B6" }}
                            checked={annotationsProvider.isPointAnnotation}
                            onChangeFxn={() => { annotationsProvider.setIsPointAnnotation(!annotationsProvider.isPointAnnotation) }}
                            checkboxLabel={"Point Annotation"}
                        />
                        <CircularCheckboxComponent
                            style={{ marginLeft: "10px", color: !annotationsProvider.isPointAnnotation ? "#207DEA" : "#B6B6B6" }}
                            checked={!annotationsProvider.isPointAnnotation}
                            onChangeFxn={() => { annotationsProvider.setIsPointAnnotation(!annotationsProvider.isPointAnnotation) }}
                            checkboxLabel={"Span Annotation"}
                        />
                    </div>
                    <div style={{ transform: "scale(0.8)", transformOrigin: "top", marginLeft: "-20px", marginTop: "-80px", zIndex: "10" }}>
                        <TimeSelector
                            selectorStyle={{ maxWidth: "385px", zIndex: "10" }}
                            calendar={true}
                            orientation="editAnnotation"
                            currentTime={selectedAnnotation.start_time}
                            currentEndTime={annotationsProvider.isPointAnnotation ? selectedAnnotation.start_time : selectedAnnotation.end_time}
                            minTime={fileStartDate}
                            maxTime={fileEndDate}
                            onChange={handleTimeChange}
                            interval={!annotationsProvider.isPointAnnotation}
                            showX={false}
                            autoSave={true}
                            timeZone={fileTimeZone}
                            enableResetChanges={(startDate !== selectedAnnotation.start_time) || endDate !== selectedAnnotation.end_time}
                        />
                    </div>

                    {errorMessage && (
                        <div style={{ color: '#E54E58', fontSize: '12px', fontFamily: 'Montserrat', fontWeight: 600, position: 'relative', bottom: '120px', left: '25px', maxWidth: '300px' }}>
                            {errorMessage}
                        </div>
                    )}
                </AnnotationIntervalContentContainer>

            </EditAnnotationContentDiv>

            <div>
                <hr style={{ border: "1px solid #293241", width: "100%", marginBottom: "0px" }} />

                <ModalFooter style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <ModalFooterButton
                        buttonText="Save"
                        color=""
                        buttonStyle={{ width: "77px", flex: "0", padding: "8px 32px" }}
                        onClickFxn={() => { handleSubmit(handleSaveAnnotation()) }}
                        disabled={!unsavedEdits || (props.isHotkeysModal && JSON.stringify(hotkeyConfig?.triggers) === '[null,null]') || annotationTags.length > 10}
                    />
                </ModalFooter>
            </div>
        </EditAnnotationContainer>
    )
}

const EditAnnotationContainer = styled.div`
    width: 766px;
    min-height: 484px;
    overflow-x: hidden;
`;

const EditAnnotationContentDiv = styled.div`
    padding-left: 30px;
    padding-right: 30px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    h1 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 150%;
        color: #293241;
    }
    p {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #293241;
    }
`;

const AnnotationTitleInput = styled.input`
    width: 610px;
    height: 40px;
    border: 1px solid #AEB7C6;
    border-radius: 6px;
    padding-left: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    color: #293241;
    line-height: 150%;
    flex: 1;
    &: focus {
        border: 1px solid #207DEA;
    }
`;

const AnnotationDescriptionTextInput = styled.textarea`
    box-sizing: border-box;
    width: 705px;
    height: 125px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 12px 12px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    resize: none;
    &:focus {
        outline: none;
    }
    &:disabled {
        background: #FAFAFA;
    }
    &::placeholder {
        color: #AEB7C6;
    }
`;

const TagsContentDiv = styled.div`
    width: 706px;
    height: 115px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    h1 {
        font-family: 'Source Sans Pro';
        font-weight: 600;
        font-size: 16px;
        color: #293241;
        line-height: 150%;
    }
`;

const ModalitiesContentContainer = styled.div`
    width: 704px;
    height: 310px;
    border-radius: 6px;
    border: 1px solid #AEB7C6;
    padding: 0px 9px 7px 9px;
    flex-wrap: wrap;
    h2 {
        font-family: 'Source Sans Pro';
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        line-height: 150%;
        color: #5F6775;
    }
`;

const AnnotationIntervalContentContainer = styled.div`
    width: 704px;
    height: 310px;
    max-height: 310px;
    border-radius: 6px;
    border: 1px solid #AEB7C6;
    padding: 9px 7px;
`;

const KeyboardButton = styled.div`
    height: 24px;
    width: fit-content;
    background: #EFF1F4;
    padding: 1.5px 10px;
    border-radius: 4px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    text-transform: lowercase;
    color: #5F6775;
    margin-right: 3px;
`;

const AssignShortcutMessage = styled.text`
    background: none;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #207DEA;
    text-align: right;
    width: fit-content;
    white-space: nowrap;
    border: none;
    margin-right: 3px;
    &::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #207DEA;
    }
`;

const AssignShortcutWarning = styled.div`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #5F6775;
    width: fit-content;
    cursor: pointer;
    margin-right: 3px;
`;